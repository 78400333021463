//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
////import CategoryPath from '@/components/Category/Path'
////import { dateRangeDays } from '@/utils/dates'

export default {
  ////components: { DataTable, CategoryPath },
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      const keys = Object.keys(query);
      if (keys.some(key => key.startsWith("f."))) {
        const filters = keys.filter(key => key.startsWith("f.")).reduce((acc, key) => {
          acc[key] = query[key];
          return acc;
        }, {});
        this.$emit("updateFilters", filters);
      }
      return this.$store.dispatch("category/getCategoryKeywords", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      //const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2)
      return [
      /*
      {
          title: "Запрос",
          show: true,
          name: "keyword",
          width: 440,
          filter: "text",
          type: "text",
      },
      */
      {
        title: "Запрос",
        name: "keyword",
        show: true,
        width: 200,
        type: "link",
        noOverflow: true,
        getLink: item => {
          return {
            route: {
              name: "KeywordInfo",
              params: {
                k_id: item.k_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.keyword,
            target: "_blank"
          };
        }
      }, {
        title: "Частотность " + (this.reportSettings.mp === "wb" ? "WB" : "OZON"),
        show: true,
        name: "shows",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Результатов по запросу",
        show: true,
        name: "tot_keyword_products",
        ////type: "number",
        width: 140,
        filter: "numberRange",
        type: "link",
        getLink: item => {
          return {
            href: item.keyword_mp_search_link,
            text: item.tot_keyword_products,
            target: "_blank"
          };
        }
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};