//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import CategoryPath from "@/components/Category/Path";
import { dateRangeDays } from "@/utils/dates";
export default {
  components: {
    DataTable,
    CategoryPath
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("category/getCategorySubcategoriesOld", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2);
      return [{
        title: "Категория",
        show: true,
        name: "c_name_path",
        width: 440,
        filter: "text",
        type: "slot"
      }, {
        title: "Товаров",
        show: true,
        name: "products",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Товаров с продажами",
        show: true,
        name: "products_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Брендов",
        show: true,
        name: "brands",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Брендов с продажами",
        show: true,
        name: "brands_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Продавцов",
        show: true,
        name: "sellers",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        title: "Продавцов с продажами",
        show: true,
        name: "sellers_with_sales",
        type: "number",
        width: 140,
        filter: "numberRange"
      }, {
        ////title: `Выручка на товар`,
        title: `Средний чек`,
        show: true,
        name: "revenue_per_product",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Продано за ${days} дн (шт)`,
        show: true,
        name: "sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Выручка за ${days} дн`,
        show: true,
        name: "revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Сред. продаж в день (шт)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Сред. выручка в день`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};