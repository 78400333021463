//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportMixin from "@/mixins/Report";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import AppTabs from "@/components/AppTabs.vue";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import CategoryProductsTable from "@/components/Category/ProductsTableOld.vue";
import CategoryBrandsTable from "@/components/Category/BrandsTableOld.vue";
import CategorySellersTable from "@/components/Category/SellersTableOld.vue";
import CategorySubcategoriesTable from "@/components/Category/SubcategoriesTableOld.vue";
import CategoryKeywordsTable from "@/components/Category/KeywordsTable.vue"; //kwd
import CategorySegmentsTable from "@/components/Category/SegmentsTable.vue";
import EntityCharts from "@/components/EntityCharts";
import { getEntityCellsConfig } from "@/utils/enums";
import ListAddItemButton from "@/components/List/AddItemButton.vue";
import CategoryPath from "@/components/Category/Path";
import SwitchInput from "@/components/SwitchInput.vue";
const tableComponents = {
  products: CategoryProductsTable,
  brands: CategoryBrandsTable,
  sellers: CategorySellersTable,
  subcategories: CategorySubcategoriesTable,
  keywords: CategoryKeywordsTable,
  //kwd
  segments: CategorySegmentsTable
};
export default {
  mixins: [ReportMixin],
  meta() {
    var _this$category;
    const name = (_this$category = this.category) === null || _this$category === void 0 ? void 0 : _this$category.c_name;
    return {
      title: name ? `Обзор показателей категории ${name}` : undefined
    };
  },
  data() {
    let cells = getEntityCellsConfig("category");
    const customCells = {
      avgPerDay: {
        title: "Среднее<br>продаж в день",
        multi: [{
          id: "avg_sold",
          unit: cells.avg_sold.unit
        }, {
          id: "avg_revenue",
          unit: cells.avg_revenue.unit
        }],
        color: cells.avg_sold.color
      },
      //&:
      losses: {
        title: `Упущенная выручка`,
        //color: cells.losses.color,    //!пока не используем losses для категорий
        color: "pink",
        multi: [{
          id: "losses",
          //unit: cells.losses.unit,
          unit: "руб"
        }]
      }
    };
    delete cells.avg_sold;
    delete cells.avg_revenue;
    cells = {
      ...cells,
      ...customCells
    };
    return {
      category: null,
      tabs_tables_all: [{
        id: "products",
        title: "Товары"
      }, {
        id: "brands",
        title: "Бренды"
      }, {
        id: "sellers",
        title: "Продавцы"
      }, {
        id: "subcategories",
        title: "Подкатегории"
      },
      /* //&: 2022-02-14 : api_CH пока не реализован
              {
                  id: 'segments',
                  title: 'Ценовая сегментация'
              },
              */

      //kwd
      {
        id: "keywords",
        title: "Поисковые запросы"
      }],
      tabs_tables_model: null,
      cells
    };
  },
  methods: {
    getTableComponent(id) {
      return tableComponents[id];
    },
    getTableId() {
      return this._uid;
    },
    async loadCategory() {
      this.category = await this.$store.dispatch("category/getCategory", {
        ...this.reportSettings
      });
    },
    loadOverview() {
      return this.$store.dispatch("category/categoryOverviewAllOld", this.reportSettings);
    }
  },
  async mounted() {},
  computed: {
    tabs_tables() {
      return this.tabs_tables_all.filter(tab => {
        if (tab.id === "subcategories") {
          var _this$category2;
          return (_this$category2 = this.category) === null || _this$category2 === void 0 ? void 0 : _this$category2.qHasChild;
        }
        return true;
      });
    },
    track() {
      return {
        url: this.$route.fullPath,
        type: `${this.$route.params.mp.toUpperCase()} / Анализ категории / ${this.tabs_tables_model.title}`,
        name: this.category.c_name,
        date: this.reportSettings.date,
        date2: this.reportSettings.date2
      };
    },
    fileName() {
      return `${this.$route.params.mp.toUpperCase()} Категория ${this.category.c_name} ${this.tabs_tables_model.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      return {
        mp: this.$route.params.mp,
        c_id: this.$route.params.c_id,
        date: this.calendar[0],
        date2: this.calendar[1],
        fbs: this.fbs,
        ...this.$route.query
      };
    },
    categoryKey() {
      return `${this.$route.params.c_id}:${this.$route.params.mp}`;
    },
    tableKey() {
      return `${this.categoryKey}:${this.tabs_tables_model.id}`;
    }
  },
  watch: {
    reportSettings: {
      handler(v) {
        if (!v) {
          return;
        }
        this.loadCategory();
      },
      immediate: true
    }
  },
  components: {
    MainTitle,
    AppTabs,
    DataTableActions,
    CategoryProductsTable,
    EntityCharts,
    ReportPage,
    ListAddItemButton,
    CategoryPath,
    SwitchInput
  }
};