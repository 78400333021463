/* unplugin-vue-components disabled */var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',_vm._b({attrs:{"table-id":"category_subcategories","id":_vm.id,"columns":_vm.columns,"loader":_vm.tableLoader,"loaderConfig":_vm.reportSettings,"item-id":"c_id"},on:{"selectAction":function ($event) { return $event.addToList({
                entity: 'category',
                mp: this$1.reportSettings.mp,
            }); }},scopedSlots:_vm._u([{key:"category",fn:function(ref){
            var item = ref.item;
            var categories = ref.categories;
return [_c('category-path',{attrs:{"block":"","path":item.c_id_path,"categories":categories,"route-query":{
                date: _vm.reportSettings.date,
                date2: _vm.reportSettings.date2,
                fbs: _vm.reportSettings.fbs,
            }}})]}}])},'data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }